/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages

          var navigationContainer = $('#cd-nav'),
              mainNavigation = navigationContainer.find('#cd-main-nav ul');

          function checkMenu() {

              if( mainNavigation.hasClass('is-visible')  && $('html').hasClass('no-csstransitions') ) {
                  mainNavigation.removeClass('is-visible has-transitions');
                  navigationContainer.removeClass('is-fixed');
                  $('.cd-nav-trigger').removeClass('menu-is-open');
                  //scrolling up with menu closed
              } else {
                  navigationContainer.removeClass('is-fixed');
                  mainNavigation.removeClass('has-transitions');
              }


          }

          //hide or show the "menu" link
          checkMenu();

          //open or close the menu clicking on the bottom "menu" link
          $('.cd-nav-trigger').on('click', function(){
              $(this).toggleClass('menu-is-open');
              //we need to remove the transitionEnd event handler (we add it when scolling up with the menu open)
              mainNavigation.off('webkitTransitionEnd otransitionend oTransitionEnd msTransitionEnd transitionend').toggleClass('is-visible');

          });


          jQuery("a.fancybox").fancybox({
              'cyclic': false,
              'autoScale': false,
              'padding': 10,
              'opacity': false,
              'speedIn': 500,
              'speedOut': 500,
              'changeSpeed': 300,
              'overlayShow': false,
              'titleShow': true,
              'width'    : 1140,
              'titlePosition': 'inside',
              'enableEscapeButton': true,
              'showCloseButton': true,
              'hideOnOverlayClick': true,
              'hideOnContentClick': false,
              'transitionIn': "fade",
              'transitionOut': "fade",
              'centerOnScroll': true,

              tpl: {
                  closeBtn : '<a title="Close" class="fancybox-item overlay-close" href="javascript:;"><img src="../../wp-content/themes/asm/dist/images/close.svg"/></a>'
              },
              helpers: {
                  overlay: {
                      locked: true
                  }
              }

      });

          $(".wpcf7").find("p").contents().unwrap();


          $('a.scroll').on('click', function (e) {
              $('html, body').animate({
                  scrollTop: $('.nav-tabs ').offset().top
              }, 'slow');
              e.preventDefault();
          });



          var locations = [
              ['ASM Sede', 40.74492,  -8.38349, 2],
              ['ASM Polo Aveiro', 40.656699, -8.596128, 1]
          ];

          var map = new google.maps.Map(document.getElementById('mapa'), {
              zoom: 16,
              center: new google.maps.LatLng(40.74492, -8.38349),
              mapTypeId: google.maps.MapTypeId.ROADMAP
          });


          var infowindow = new google.maps.InfoWindow();

          var marker, i;






          for (i = 0; i < locations.length; i++) {
              marker = new google.maps.Marker({
                  position: new google.maps.LatLng(locations[i][1], locations[i][2]),
                  map: map,
                  icon: "../../wp-content/themes/asm/dist/images/map-marker.png"
              });

              google.maps.event.addListener(marker, 'click', (function(marker, i) {
                  return function() {
                      infowindow.setContent(locations[i][0]);
                      infowindow.open(map, marker);
                  };
              })(marker, i));
          }


          $('a[href="#aveiro"]').on('shown.bs.tab', function(e){
              map.setCenter({lat: 40.656699, lng: -8.596128});
              map.setZoom(16);
          });

          $('a[href="#sede"]').on('shown.bs.tab', function(e){
              map.setCenter({lat: 40.74492, lng: -8.38349});
              map.setZoom(16);
          });


          $('ul.nav.nav-tabs a:first').tab('show'); // Select first tab
          $('ul.nav.nav-tabs a[href="'+ window.location.hash+ '"]').tab('show'); // Select tab by name if provided in location hash
          $('ul.nav.nav-tabs a[data-toggle="tab"]').on('shown', function (event) {    // Update the location hash to current tab
              window.location.hash= event.target.hash;
          });





      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },

      'contactos': {
          init: function() {









          } // end init

      },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
      'produtos_e_servicos': {
          init: function() {
              // JavaScript to be fired on the home page
              $('ul.nav.nav-tabs a:first').tab('show'); // Select first tab
              $('ul.nav.nav-tabs a[href="'+ window.location.hash+ '"]').tab('show'); // Select tab by name if provided in location hash
              $('ul.nav.nav-tabs a[data-toggle="tab"]').on('shown', function (event) {    // Update the location hash to current tab
                  window.location.hash= event.target.hash;
              });

          },
          finalize: function() {
              // JavaScript to be fired on the home page, after the init JS
          }
      },
      'projetos': {
          init: function() {
              // JavaScript to be fired on the home page
              $('ul.nav.nav-tabs a:first').tab('show'); // Select first tab
              $('ul.nav.nav-tabs a[href="'+ window.location.hash+ '"]').tab('show'); // Select tab by name if provided in location hash
              $('ul.nav.nav-tabs a[data-toggle="tab"]').on('shown', function (event) {    // Update the location hash to current tab
                  window.location.hash= event.target.hash;
              });



          },
          finalize: function() {
              // JavaScript to be fired on the home page, after the init JS
          }
      }
  };





  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);




})(jQuery); // Fully reference jQuery after this point.





var owlWrap = $('.owl-wrapper');
// checking if the dom element exists
if (owlWrap.length > 0) {
    // check if plugin is loaded
    if (jQuery().owlCarousel) {
        owlWrap.each(function(){
            var carousel= $(this).find('.owl-carousel'),
                navigation = $(this).find('.customNavigation'),
                nextBtn = navigation.find('.next'),
                prevBtn = navigation.find('.prev');


            carousel.owlCarousel({
                items : 5, //10 items above 1000px browser width
                itemsDesktop : [1199,5], //5 items between 1000px and 901px
                itemsDesktopSmall : [979,4], // 3 items betweem 900px and 601px
                itemsTablet: [768,3], //2 items between 600 and 0;
                itemsMobile : [479,1], // itemsMobile disabled - inherit from itemsTablet option
                itemsScaleUp:false,
                responsive:	true,
                autoHeight:false,
                navigation  : false,
                stopOnHover : true,
                autoPlay    : false,
                pagination  : true
            });

            // Custom Navigation Events
            nextBtn.click(function(){
                carousel.trigger('owl.next');
            });
            prevBtn.click(function(){
                carousel.trigger('owl.prev');
            });


        });
    }
}




